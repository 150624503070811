// var remote = inElectron() ? require('electron').remote : false;
// this.mainWindow = inElectron() ? remote.getCurrentWindow() : false;

(function ($, Promise) {

		// Setup the prototype inheritance
		extend(BaseGame, MainApp);

		// Constructor
		function MainApp() {

			// Super constructor
			BaseGame.call(this);
			this.i18n.set("fr");

			// Data
			this.data = false;

			// Screensaver
			this.screensaverTimeline = false;
			this.screenAnimTimeline = false;

			// Wait for all
			Promise.all([
				// Load settings.json
				this.loadSettings(),
			]).then($.proxy(function () {
				// Load data.json
				return this.loadData();
			}, this)).then($.proxy(function () {
				// Go to loading screen
				// Override locking logic
				this.goToScreen("loading");
				this.lockScreen = false;
				return this.buildContent();
			}, this)).then($.proxy(function () {
				// Real game init
				return this.initGame();
			}, this));

		}

		// Load settings JSON (with promise)
		MainApp.prototype.loadSettings = function () {
			return new Promise($.proxy(function (resolve, reject) {

				$.getJSON("settings.json").done($.proxy(function (data) {
					this.settings = data;

					// Init screensaver
					if (typeof this.settings.screensaver_delay != "undefined") {
						this.screensaverDelay = this.settings.screensaver_delay;
					}

					resolve();

				}, this)).fail(function () {
					alert("The settings.json file was not found. Please add the setting file and restart app.");
					if (gui) {
						gui.App.quit();
					}

					reject();
				});
			}, this));
		};

		// Load data JSON (with promise)
		MainApp.prototype.loadData = function () {
			return new Promise($.proxy(function (resolve, reject) {
				$.getJSON(this.settings.data_path).done($.proxy(function (data) {
					this.data = data;
					resolve();
				}, this)).fail(function () {
					alert("No data found. Please add the data file and restart the app.");
					reject();
				});
			}, this));
		};

		MainApp.prototype.initGame = function () {

			// Disable right click
			if (inElectron()) {
				$(document).contextmenu(function (e) {
					e.preventDefault();
					e.stopPropagation();
					return false;
				});
			}

			// Temporary function
			$(document).on("keydown", $.proxy(function (e) {
				if (e.keyCode === 32) {
					$("#reference-image").toggle();
				}
			}, this));


			this.goToScreen(1);
			// this.goToScreen(2);
			// this.selectTheme(0);
			// this.selectTheme(1);
			// setTimeout($.proxy(this.zoomImageIn, this), 1000)


		};

		MainApp.prototype.initScreen = function (screenId) {

			// Super constructor
			if (BaseGame.prototype.initScreen.call(this, screenId) == false) {
				return false;
			}

			switch (screenId) {
				case 1:
					this.screensaverTimeline.seek(0).play();
					break;
				case 2:
					this.screensaverTimeline.pause().seek(0);
					this.animateScreenIn(screenId);
					break;
				case 3:
					this.screensaverTimeline.pause().seek(0);
					this.animateScreenIn(screenId);
					break;
			}
		}

		MainApp.prototype.buildContent = function () {
			return new Promise($.proxy(function (resolve, reject) {

				var totalImagesToLoad = 0;
				var totalImagesLoaded = 0;

				// Screen 1
				$(this.data.screensaver.images).each($.proxy(function (i, n) {
					var $screensaverItem = $("<div class='item'><div class='scale-wrapper'><img draggable='false'/></div></div>");
					var $img = $screensaverItem.find("img");
					$screensaverItem.addClass(n.origin.replace(" ", "-"))
					$img.attr("src", n.src);
					$("#screensaver-wrapper").append($screensaverItem);
				}, this));

				this.initScreenSaver();

				// Screen 2

				var $homepageMenuItemModel = $("#homepage-menu .item.model").remove().removeClass("model");

				$(this.data.themes).each($.proxy(function (i, n) {

					var $homepageMenuItem = $homepageMenuItemModel.clone();
					$homepageMenuItem.attr("id", n.name)
					$homepageMenuItem.css("background-image", "url('" + n.homepage_image + "')")

					$homepageMenuItem.find(".title-image").attr("src", n.homepage_title);

					$homepageMenuItem.find(".description .fr").html(n.description.fr);
					$homepageMenuItem.find(".description .en").html(n.description.en);

					$homepageMenuItem.find(".title.trigger").data("action-arg", i.toString());
					$homepageMenuItem.find(".discover.trigger").data("action-arg", i.toString());

					$("#homepage-menu").append($homepageMenuItem);

				}, this));

				// Screen 3

				var $libraryThemeModel = $("#library .theme.model").remove().removeClass("model");

				$(this.data.themes).each($.proxy(function (i, n) {

					var $libraryTheme = $libraryThemeModel.clone();
					$libraryTheme.attr("id", "theme-" + n.name)
					$libraryTheme.find(".background").css("background-image", "url('" + n.gallery_background_image + "')")
					$libraryTheme.find(".title-image").attr("src", n.gallery_title);
					$libraryTheme.find(".zoom-watermark").attr("src", n.zoom_watermark_image);

					var $itemViewerModel = $libraryTheme.find(".item-viewer .item.model").remove().removeClass("model");
					var $itemThumbnailModel = $libraryTheme.find(".gallery-thumbs .item.model").remove().removeClass("model");

					$(n.photos).each($.proxy(function (i2, n2) {

						// Big image
						var $viewerItem = $itemViewerModel.clone();
						$viewerItem.find("img.image").attr("src", n2.filename);
						$viewerItem.find(".description .fr").html(n2.full_caption.fr.replace(/\n/g, "<br/>"));
						$viewerItem.find(".description .en").html(n2.full_caption.en.replace(/\n/g, "<br/>"));
						$libraryTheme.find(".item-viewer .swiper-wrapper").append($viewerItem);

						// Resize wrapper on image load
						totalImagesToLoad++;
						$viewerItem.find("img.image").on("load", $.proxy(function (e) {

							// Loafing images, his is where promise resolve
							if (parseInt(e.target.height) > 0 && parseInt(e.target.width) > 0) {

								// Trigger image resize
								this.resizeSliderImage(e.target);

								// Update total count and handle resolving
								totalImagesLoaded++;
								$("#loading-count").text(totalImagesLoaded + " / " + totalImagesToLoad)
								if (totalImagesToLoad == totalImagesLoaded) {
									resolve();
								}
							}

						}, this)).each(function () {
							// Make sure it is triggered even from cache.
							requestAnimationFrame($.proxy(function () {
								if (this.complete) {
									$(this).trigger('load');
								}
							}, this));
						});

						// Thumbnail
						var $thumbItem = $itemThumbnailModel.clone();
						$thumbItem.find("img.image").attr("src", n2.filename);
						$libraryTheme.find(".gallery-thumbs .swiper-wrapper").append($thumbItem);

					}, this));

					// Set total count
					$libraryTheme.find(".count-total").text(n.photos.length);

					// Add theme div
					$("#library").append($libraryTheme);

					// Init swiper sliders
					this.initSliders($libraryTheme);

				}, this));

			}, this));
		}

		MainApp.prototype.initSliders = function ($el) {

			var galleryThumbs = new Swiper($el.find('.gallery-thumbs'), {
				// spaceBetween: 10,
				slidesPerView: 'auto',
				// centeredSlides: true,
				// slidesPerView: 8,
				slidesPerView: "auto",
				// freeMode: true,
				watchSlidesVisibility: true,
				watchSlidesProgress: true,
			});

			var gallery = new Swiper($el.find('.gallery-top'), {
				spaceBetween: 10,
				effect: "fade",
				preventClicksPropagation: false,
				fadeEffect: {
					crossFade: true
				},
				navigation: {
					nextEl: $el.find('.button-next'),
					prevEl: $el.find('.button-prev'),
				},
				thumbs: {
					swiper: galleryThumbs
				},
				on: {
					slideChange: function () {
						$(this.el).parents(".theme").find(".count-current").text(parseInt(this.activeIndex) + 1)
					},
				},
			});

		}

		MainApp.prototype.initScreenSaver = function () {

			// Super
			BaseGame.prototype.initScreenSaver.call(this);

			this.screensaverTimeline = new TimelineMax({
				repeat: -1,
			});

			$("#screensaver-wrapper .item").each($.proxy(function (i, n) {
				this.screensaverTimeline.fromTo(n, 0.5, {
					autoAlpha: 0,
				}, {
					autoAlpha: 1,
				});
				this.screensaverTimeline.fromTo($(n).find(".scale-wrapper"), (parseFloat(this.data.screensaver.duration) / 1000), {
					scale: 1,
				}, {
					scale: (1 / 1.3),
				}, "-=0.5");
				this.screensaverTimeline.to(n, 0.5, {
					autoAlpha: 0,
				}, "-=0.5");
				this.screensaverTimeline.set({}, {}, "+=" + (parseFloat(this.data.screensaver.pause) / 1000).toString())
			}, this));
		}

		MainApp.prototype.start = function () {
			BaseGame.prototype.start.call(this);
			this.goToScreen(2);
		}

		MainApp.prototype.goToHome = function () {
			this.goToScreen(2, $.proxy(this.resetGalleries, this));
		}

		MainApp.prototype.goToScreenSaver = function () {
			this.reset(1);
		}

		MainApp.prototype.selectTheme = function (theme) {
			console.log("selectTheme")
			$("#library .theme").hide().removeClass("active");
			$("#library .theme").eq(theme).show().addClass("active");
			$("#library .theme").eq(theme).show().addClass("active");
			this.goToScreen(3, $.proxy(function () {
				console.log("here")
				requestAnimationFrame($.proxy(function () {
					this.resizeSliderImage($("#library .theme.active .gallery-top img.image"));
				}, this));
			}, this));

		}

		MainApp.prototype.zoomImageIn = function () {

			// Disable click during animation
			this.disableClick(true);

			$("#screen-3 .cloned-zoom-image").remove()
			$("#library").addClass("zooming");

			$originalImage = $("#library .theme.active .gallery-top .swiper-slide-active img.image");

			// Define new positon values
			var offset = $originalImage.offset();
			var posY = offset.top - $(window).scrollTop();
			var posX = offset.left - $(window).scrollLeft();
			var width = $originalImage.width();
			var height = $originalImage.height();
			$cloned = $originalImage.clone();
			$cloned.addClass("cloned-zoom-image");
			$cloned.css({
				position: "fixed",
				top: 40,
				left: posX + (width - (890 * (width / height))) / 2,
				width: 890 * (width / height),
				height: 890,
				zIndex: 1,
			});

			// Animate elements
			TweenMax.to($("#library .theme.active .gallery-thumbs"), 0.5, {
				autoAlpha: 0,
				y: 15,
			});

			TweenMax.fromTo($cloned, 0.5, {
				scale: height / 890,
				y: 20,
				z: 0.01,
			}, {
				scale: 1,
				y: 0,
				z: 0.01,
				onComplete: $.proxy(function () {

					// handle classes and DOM
					$("#library").removeClass("zooming").addClass("zoomed");
					$("#screen-3 .cloned-zoom-image").remove();

					// Trigger resize of image and images wrappers
					this.resizeSliderImage($("#library .theme.active .gallery-top img.image"));

					$("#library .theme.active .gallery-top").get(0).swiper.update();

					// Re-enable click
					this.disableClick(false);

				}, this)
			});
			$("#screen-3").append($cloned)
		}

		MainApp.prototype.zoomImageOut = function (disableClick) {

			// Disable click during animation
			if (typeof disableClick == "undefined" || disableClick === true) {
				this.disableClick(true);
			}

			// Stop if not zoommed
			if (!$("#library").hasClass("zooming") && !$("#library").hasClass("zoomed")) {
				return;
			}

			TweenMax.to($("#library .theme.active .gallery-top"), 0.35, {
				y: -30,
				opacity: 0,
				onComplete: $.proxy(function () {

					// handle classes and DOM
					$("#library").removeClass("zooming zoomed");

					// Trigger resize of image and images wrappers
					this.resizeSliderImage($("#library .theme.active .gallery-top img.image"));

					$("#library .theme.active .gallery-top").get(0).swiper.update();

					TweenMax.to($("#library .theme.active .gallery-thumbs"), 0.5, {
						autoAlpha: 1,
						y: 0,
					});

					TweenMax.to($("#library .theme.active .gallery-top"), 0.5, {
						y: 0,
						opacity: 1,
						onComplete: $.proxy(function () {
							// Re-enable click
							this.disableClick(false);
						}, this),
					});
				}, this)
			});
		}

		MainApp.prototype.resizeSliderImage = function (images) {

			var containerHeight = $(images).parents(".image-wrapper").height();
			var containerWidth = $(images).parents(".image-wrapper").width();
			var containerRatio = containerHeight / containerWidth;

			// This is very complicated... due to performance improvements
			$(images).each($.proxy(function (i, image) {

				// console.log("resizeSliderImage")

				var $image = $(image);
				var resizeThumbnail;

				if ($image.data("ratio")) {
					// Second time
					var imageRatio = $image.data("ratio");
					$image.addClass("ready")
					resizeThumbnail = false;

				} else {
					// First time
					if (parseInt(image.height) <= 0 || parseInt(image.width) <= 0) {
						return;
					}
					var imageRatio = image.height / image.width;
					$image.data("ratio", imageRatio);
					resizeThumbnail = true;
				}

				if (imageRatio > containerRatio) {
					$(image).parents(".image-inner-wrapper").css({
						"width": containerHeight / imageRatio,
						"height": containerHeight
					});
				} else {
					$(image).parents(".image-inner-wrapper").css({
						"width": containerWidth,
						"height": containerWidth / imageRatio
					});
				}

				// Set related thumbnail width
				if (resizeThumbnail) {
					var index = $(image).parents(".item").index();
					var $activeTheme = $(image).parents(".theme");
					var thumbsHeight = $(".gallery-thumbs").height();
					$activeTheme.find(".gallery-thumbs .item").eq(index).css("width", (thumbsHeight / imageRatio) + "px");
				}

			}, this));
		}

		MainApp.prototype.animateScreenIn = function (screenId) {

			if (this.screenAnimTimeline != false) {
				this.screenAnimTimeline.kill();
				this.screenAnimTimeline = false;
			}

			// Create new timeline
			this.screenAnimTimeline = new TimelineMax({
				repeat: 0,
				autoPlay: true,
			});

			// Add pause
			this.screenAnimTimeline.add(function () {
			}, 0.5);


			if (screenId == 2) {

				// Animate titles
				$(".info-wrapper", "#screen-" + screenId).each($.proxy(function (i, n) {
					this.screenAnimTimeline.fromTo(n, 0.5, {
						autoAlpha: 0,
						y: -15,
					}, {
						autoAlpha: 1,
						y: 0,
					}, "-=0.25");
				}, this));

				// Animate button
				$(".discover.trigger", "#screen-" + screenId).each($.proxy(function (i, n) {
					this.screenAnimTimeline.fromTo(n, 0.5, {
						autoAlpha: 0,
						y: -15,
					}, {
						autoAlpha: 1,
						y: 0,
					}, "-=0.25");
				}, this));

			} else if (screenId == 3) {

				// Animate elements
				$(".theme.active header, .theme.active .item-viewer,  .theme.active .gallery-thumbs", "#screen-" + screenId).each($.proxy(function (i, n) {
					this.screenAnimTimeline.fromTo(n, 0.75, {
						autoAlpha: 0,
						y: -15,
					}, {
						autoAlpha: 1,
						y: 0,
					}, "-=0.25");
				}, this));

			}

		}

		MainApp.prototype.disableClick = function (value) {
			$("#disable-click").toggle(value)
		}

		MainApp.prototype.resetGalleries = function () {

			// Zoom image out if required
			this.zoomImageOut(false);

			// Go to slide 1
			$('.gallery-thumbs').each(function (i, n) {
				n.swiper.slideTo(0, 0);
			});
			$('.gallery-top').each(function (i, n) {
				n.swiper.slideTo(0, 0);
			});

		}

		// Reset values when we return to the homepage
		MainApp.prototype.reset = function (screenId) {

			if (typeof screenId == "undefined") {
				screenId = 1;
			}

			if (BaseGame.prototype.reset.call(this) == false) {
				return false;
			}

			// Disable click during animation

			// this.currentSlate = false;
			// this.slateTimer = false;
			// this.slatePlayProgress = 0;
			//
			// this.gotoSlate(1);
			// if (this.slateTimer !== false) {
			// 	clearInterval(this.slateTimer);
			// 	this.slateTimer = false;
			// }

			console.log("this 2", this)

			// Return to idle screen
			this.goToScreen(screenId, $.proxy(function () {

				// Reset galleries
				this.resetGalleries();

				// Reset language
				if (this.i18n.lang == "en") {
					this.i18n.flip();
				}


			}, this));
		};

		// Expose MainApp
		window.MainApp = MainApp;
	}
)
(jQuery, Promise);
