// Import nodejs library
const SerialPort = (typeof require != "undefined") ? require('serialport') : false;

(function ($, Promise) {

		// Constructor
		function Serial(baudRate, lookFor, maximumInstances, autoReconnect) {

			// Event dispatcher
			this.dispatcher = $({});
			this.portsAvailable = [];
			this.serialportsPasers = [];
			this.serialPorts = [];
			this.runningFromBrowser;
			this.closing = false;

			this.maximumInstances = (typeof maximumInstances != "undefined") ? maximumInstances : Number.POSITIVE_INFINITY;
			this.autoReconnect = (typeof autoReconnect != "undefined") ? autoReconnect : false;

			this.init(baudRate, lookFor);
		}

		// Load settings JSON (with promise)
		Serial.prototype.init = function (baudRate, lookFor) {
			if (SerialPort !== false) {
				this.runningFromBrowser = false;


				// SerialPort.list().then(ports => {
				//   console.log('ports', ports);
				//   tableHTML = "";
				//
				//
				//   "/dev/tty.usbserial-AB0JPNCX"
				//
				// });

				SerialPort.list().then($.proxy(function (ports) {
					try {

						ports.forEach($.proxy(function (port) {

							var id = (typeof port.pnpId != "undefined") ? port.pnpId : port.comName;

							// If manufacturer contains ARDUINO, make a connection
							if ((typeof port.manufacturer != "undefined" && port.manufacturer.toLowerCase().indexOf(lookFor.toLowerCase()) !== -1) || (typeof port.comName != "undefined" && port.comName.toLowerCase().indexOf(lookFor.toLowerCase()) !== -1) || (typeof port.pnpId != "undefined" && port.pnpId.toLowerCase().indexOf(lookFor.toLowerCase()) !== -1)) {
								this.portsAvailable.push(port);
								console.log(port.comName + " / " + port.manufacturer + " (" + id + ") is available and meet requirements.");
							} else {
								console.log(port.comName + " / " + port.manufacturer + " (" + id + ") doesn't meet reqiremend and will not be used.");
							}

						}, this));

						if (this.portsAvailable.length > 0) {
							this.startSerialServers(baudRate);
							this.dispatcher.trigger($.Event('serial-ready'));
						}

					} catch (err) {
						console.error(err);
					}

				}, this));

			} else {
				this.runningFromBrowser = true;
			}
		};


		Serial.prototype.startSerialServers = function (baudRate) {

			// Go through each defined serial port
			for (var i = 0; i < Math.min(this.portsAvailable.length, this.maximumInstances); i++) {

				var port = this.portsAvailable[i];

				// Create new parser
				// https://arduino.stackexchange.com/questions/42883/sending-string-over-serial-to-nodejs-app-why-does-it-get-chunked
				this.serialportsPasers.push(new SerialPort.parsers.Readline("\n"));

				// Create an instance to each port defined in settings.json
				this.serialPorts.push(new SerialPort(port.comName, {
					baudRate: baudRate,
					autoOpen: false,
				}));

				// Link serial Parser to serial port
				this.serialPorts[i].pipe(this.serialportsPasers[i])

				console.log('Initializing serial Port using : ' + port.comName + " / " + port.manufacturer);

				// Bind events : open
				this.serialPorts[i].on('open', $.proxy(function (port) {
					console.log('SerialPort open', port);
				}, this, this.serialPorts[i]));

				// Bind events : on parser data
				this.serialportsPasers[i].on('data', $.proxy(function (port, data) {
					try {
						var receivedString = ("" + data.toString()).trim();
						console.log('SerialPort received message : ' + receivedString);
						var event = $.Event('message');
						event.message = receivedString;
						this.dispatcher.trigger(event);
					} catch (e) {
						console.log("error", e);
					}

				}, this, this.serialPorts[i]));

				// Bind events : error
				this.serialPorts[i].on('error', $.proxy(function (prt, err) {
					console.error('SerialPort error', port, err);
				}, this, this.serialPorts[i]));

				// Bind events : close
				this.serialPorts[i].on('close', $.proxy(function (port, err) {

					console.log('SerialPort onClose', port, err);

					if (err.disconnected == true) {
						console.log('SerialPort disconnect', port);
						var event = $.Event('serial port closed');
						this.dispatcher.trigger(event);

					} else {
						console.log('SerialPort close', port);
						var event = $.Event('serial port closed');
						this.dispatcher.trigger(event);

						// try to reconnect it
						if (!this.closing && (this.autoReconnect === true)) {
							var reconnect = setInterval($.proxy(function (port) {

								port.open(function (err) {
									console.log("Trying to reopen port...", port);

									if (err) {
										return console.log('Error opening port: ', err.message);
									} else {
										clearInterval(reconnect);
										this.dispatcher.trigger($.Event('serial-ready'));
										return console.log('Successfully reopened port!', port);
									}
								});

							}, this, port), 5000);
						}
					}
				}, this, this.serialPorts[i]));

			}

			// Go through each defined serial port and open connections
			for (var i = 0; i < this.serialPorts.length; i++) {
				this.serialPorts[i].open($.proxy(function (err) {
					if (err) {
						var message = 'Error opening port: ' + err.message;
						this.dispatcher.trigger($.Event('error', message.toString()));
						return;
					}
					// Because there's no callback to write, write errors will be emitted on the port:
					// this.serialports[i].write('main screen turn on');
				}, this));
			}
		}

		Serial.prototype.close = function () {
			this.closing = true;
			for (var i = 0; i < this.serialPorts.length; i++) {
				this.serialPorts[i].close();
			}
		}

		Serial.prototype.write = function (data) {
			for (var i = 0; i < this.serialPorts.length; i++) {
				this.serialPorts[i].write(data);
			}
		}

		Serial.prototype.on = function () {
			this.dispatcher.on.apply(this.dispatcher, arguments)
		}

		Serial.prototype.off = function () {
			this.dispatcher.off.apply(this.dispatcher, arguments)
		}

		Serial.prototype.one = function () {
			this.dispatcher.one.apply(this.dispatcher, arguments)
		}


		// Expose Serial
		window.Serial = Serial;
	}
)
(jQuery, Promise);
