var remote = window.inElectron() ? require('electron').remote : false;

(function ($, Promise) {

// Constructor
	function BaseGame() {

		this.ready = false;
		this.started = false;

		// Hold current screen
		this.currentScreen = 0;

		// Hold current screen
		this.activateToolbarFrom = 1;

		// Touch event valid with the NWJS  version <= 38
		this.touchEvent = (('ontouchstart' in window) || window.navigator.maxTouchPoints > 0 || (window.DocumentTouch && document instanceof DocumentTouch)) && (window.getURLParameter("forcetouchevent") != "click") ? "touchstart" : "click";

		// Add accessibilities features
		this.touchEvent += " keydown";

		// Init i18n
		this.i18n = new i18n(this);

		// Init Audio
		this.audio = new AudioPlayer(this);

		// Init buttons
		this.initButtons();

		// When opened in nwjs disable right-click
		if (inElectron()) {
			$("body").css("cursor", "none").on("contextmenu", function (e) {
				return false;
			});
			$(document).contextmenu(function (e) {
				e.preventDefault();
				e.stopPropagation();
				return false;
			});
		}

		// Screensaver
		this.screensaverDelay = 30;
		this.lastClick = 0;
		this.lastReset = 0;
	}

	BaseGame.prototype.initScreenSaver = function () {

		// Timeout (screensaver) related
		this.resetTimer();
		$(document).on("click touchmove touchstart touchend", $.proxy(function (e) {
			this.resetTimer();
		}, this));

		requestInterval($.proxy(this.checkTimer, this), 1000);
	}

	BaseGame.prototype.initButtons = function () {

		// Init big buttons dynamic action
		$("#main").on(this.touchEvent, ".help, .trigger, .button, .click-sound", $.proxy(function (e) {

			if (e.type === 'keydown' && !(e.keyCode === 13 || e.keyCode === 32)) {
				return;
			}

			var el = $(e.currentTarget);
			if (typeof el.data("action") != "undefined") {
				var action = el.data("action")
				if (typeof this[action] == "function") {
					// Action is a function
					var functionArg = (typeof el.data("action-arg") != "undefined" ? el.data("action-arg") : null);
					console.log("BaseGame: Calling function : " + action + "(" + ((functionArg !== null) ? functionArg : "") + ")");

					requestTimeout($.proxy(function (e) {
						if (functionArg !== null) {
							this[action](functionArg, e);
						} else {
							this[action](e);
						}
					}, this, e), 50)
				} else {
					console.log("BaseGame: Action not found");
				}
			}

			// Play audio click
			if (typeof this.settings.click_sound !== "undefined") {
				if (this.settings.click_sound != "") {
					this.audio.play(this.settings.click_sound);
				} else {
					console.log("Audio disabled.")
				}
			} else {
				this.audio.play("audio/default_click.wav");
			}

		}, this));

	};

	BaseGame.prototype.start = function () {
		if (!this.ready || this.started) {
			return;
		}

		this.started = true;

		// To be completed in child games
	}

	BaseGame.prototype.goToScreenWithSetTimeout = function (screenId) {
		setTimeout($.proxy(function () {
			this.goToScreen(screenId);
		}, this), 1000)
	}

	BaseGame.prototype.goToScreen = function (screenId, callback) {

		// Stop on lock
		if (this.lockScreen) {
			return false;
		}

		this.lockScreen = true;

		// Show or hide toolbar
		$("#toolbar").toggleClass("active", screenId > this.activateToolbarFrom);

		//Animate in/out + callback
		if ($(".screen.current").length > 0 && $("#screen-" + screenId).length > 0) {

			// If there is a current screen and a new screen, fade out current and fade in new, then callback
			$(".screen.current").removeClass("current").animate({
				opacity: 0,
			}, "normal", $.proxy(function () {
				$("body").removeClass("current-screen-" + this.currentScreen).addClass("current-screen-" + screenId);
				$(".screen").addClass("hidden");

				this.initScreen(screenId);

				$("#screen-" + screenId).removeClass("hidden").animate({
					opacity: 1,
				}, "normal", $.proxy(function () {
					this.currentScreen = screenId;
					this.lockScreen = false;
					$("#screen-" + screenId).addClass("current");
					if (typeof callback == "function") {
						callback();
					}
				}, this));

			}, this));

		} else if ($(".screen.current").length == 0 && $("#screen-" + screenId).length > 0) {
			$("body").removeClass("current-screen-" + this.currentScreen).addClass("current-screen-" + screenId);
			$(".screen").addClass("hidden");

			this.initScreen(screenId);

			// If there is no current screen and a new screen,fade in new, then callback
			$("#screen-" + screenId).removeClass("hidden").animate({
				opacity: 1,
			}, "normal", $.proxy(function () {
				this.currentScreen = screenId;
				this.lockScreen = false;
				$("#screen-" + screenId).addClass("current");
				if (typeof callback == "function") {
					callback();
				}
			}, this));

		} else if (screenId == 0 || $(".screen.current").length > 0 && $("#screen-" + screenId).length == 0) {

			// If there is a current screen and no new screen, fade out current then callback
			$(".screen.current").removeClass("current").animate({
				opacity: 0,
			}, "normal", $.proxy(function () {
				$("body").removeClass("current-screen-" + this.currentScreen).addClass("current-screen-" + screenId);
				$(".screen").addClass("hidden");

				this.initScreen(screenId);

				this.currentScreen = screenId;
				this.lockScreen = false;
				if (typeof callback == "function") {
					callback();
				}
			}, this));
		}

		return true;
	}

	BaseGame.prototype.goToNextScreen = function (callback) {
		// Super constructor
		this.goToScreen(this.currentScreen + 1, callback);
	};

	BaseGame.prototype.goToPreviousScreen = function (callback) {
		// Super constructor
		this.goToScreen(this.currentScreen - 1, callback);
	};

	BaseGame.prototype.initScreen = function (screenId) {
		// To be overriden in child games
	}

	BaseGame.prototype.resetTimer = function () {
		this.lastClick = millis();
	}

	BaseGame.prototype.checkTimer = function (e) {
		var noActionSince = millis() - this.lastClick;
		if (noActionSince > (this.screensaverDelay * 1000) && this.screensaverDelay != -1) {
			if (this.shouldReset()) {
				this.goToScreenSaver();
			}
		}
	}

	BaseGame.prototype.goToScreenSaver = function () {
		// To be overriden in child games
	}

	BaseGame.prototype.shouldReset = function () {

		if (millis() - this.lastReset < 3000) {
			return false;
		}

		this.lastClick = millis();
		this.lastReset = millis();

		if (this.currentScreen == 1) {
			return false;
		}

		return true;
	}

	BaseGame.prototype.reset = function () {
		this.started = false;
	}

	// Expose Toposequence
	window.BaseGame = BaseGame;

})(jQuery, Promise);
