(function ($, Promise) {

// AudioPlayer handling class
  function AudioPlayer() {
    this.audios = [];
  }

  AudioPlayer.prototype.play = function (filename, forcePlay) {
    forcePlay = typeof forcePlay !== "undefined" ? forcePlay : false;

    // First check if the sound is already playing so we do not start it again
    var isPlaying = this.isPlaying(filename);

    if (isPlaying && !forcePlay) {
      console.log("Track already playing and no force play defined", filename, this.audios);
      return;
    }

    var randID = window.guid();
    var audio = document.createElement('audio');
    $(audio).attr("id", randID);

    // add the audio to the body so we can safely delete it later on
    $("body").append(audio);

    audio.src = filename;

    $(audio).one("ended", $.proxy(function () {
      var $el = $("#" + randID);
      $el.attr("src", "");
      $el.get(0).load();
      $el.off().remove();

      this.clearTrack(randID);
    }, this));

    audio.play();

    this.audios.push({
      el: audio,
      id: randID,
      filename: filename
    });

    console.log("Will play", filename, this.audios);
  }

  AudioPlayer.prototype.clearTrack = function (id) {
    for (var i = 0; i < this.audios.length; i++) {

      if (this.audios[i].id == id) {
        console.log("Track cleared", id, this.audios[i].filename);
        this.audios.splice(i, 1);
        console.log(this.audios);
        break;
      }
    }
  }

  AudioPlayer.prototype.isPlaying = function (filename) {
    var playing = false;

    for (var i = 0; i < this.audios.length; i++) {
      if (this.audios[i].filename == filename) {
        playing = true;
        break;
      }
    }

    return playing;
  };

  // Expose Fusee
  window.AudioPlayer = AudioPlayer;

})(jQuery, Promise);

